import {  Navigate } from "react-router-dom";
import { SELECTION } from "../consts";
import { useAuth } from "../../shared/hooks/UseAuth";
const NotAuth= ({children})=>{
  const {isAuth} = useAuth()
    if(isAuth) {
        return <Navigate to={SELECTION}/>
    }
    return (<>{children}</>)
    }
export default NotAuth;